import "./App.css";
import Debouncing from "./components/Debouncing";
function App() {
  return (
    <div className="App">
      <Debouncing />
    </div>
  );
}

export default App;
